import { Override } from "framer"
import { useEffect, useState } from "react"

function createCountryOverride(countryCode: string): () => Override {
    return function locationOverride(): Override {
        const [shouldShow, setShouldShow] = useState(false)

        useEffect(() => {
            async function checkLocation() {
                try {
                    const response = await fetch(
                        "https://1.1.1.1/cdn-cgi/trace"
                    )
                    const data = await response.text()
                    const lines = data.split("\n")
                    const loc = lines.find((line) => line.startsWith("loc="))
                    const country = loc ? loc.split("=")[1] : null
                    // console.log("Current country:", country)
                    setShouldShow(country === countryCode)
                } catch (error) {
                    console.error("Error getting location:", error)
                    setShouldShow(false)
                }
            }
            checkLocation()
        }, [])

        return {
            initial: { opacity: shouldShow ? 0 : 1 },
            animate: {
                opacity: shouldShow ? 0 : 1,
                display: !shouldShow ? undefined : "none",
            },
            transition: { duration: 0 },
        }
    }
}

export function afghanistan(): Override {
    return createCountryOverride("AF")()
}
export function albania(): Override {
    return createCountryOverride("AL")()
}
export function algeria(): Override {
    return createCountryOverride("DZ")()
}
export function andorra(): Override {
    return createCountryOverride("AD")()
}
export function angola(): Override {
    return createCountryOverride("AO")()
}
export function antiguaandbarbuda(): Override {
    return createCountryOverride("AG")()
}
export function argentina(): Override {
    return createCountryOverride("AR")()
}
export function armenia(): Override {
    return createCountryOverride("AM")()
}
export function australia(): Override {
    return createCountryOverride("AU")()
}
export function austria(): Override {
    return createCountryOverride("AT")()
}
export function azerbaijan(): Override {
    return createCountryOverride("AZ")()
}

export function bahamas(): Override {
    return createCountryOverride("BS")()
}
export function bahrain(): Override {
    return createCountryOverride("BH")()
}
export function bangladesh(): Override {
    return createCountryOverride("BD")()
}
export function barbados(): Override {
    return createCountryOverride("BB")()
}
export function belarus(): Override {
    return createCountryOverride("BY")()
}
export function belgium(): Override {
    return createCountryOverride("BE")()
}
export function belize(): Override {
    return createCountryOverride("BZ")()
}
export function benin(): Override {
    return createCountryOverride("BJ")()
}
export function bhutan(): Override {
    return createCountryOverride("BT")()
}
export function bolivia(): Override {
    return createCountryOverride("BO")()
}
export function bosniaandherzegovina(): Override {
    return createCountryOverride("BA")()
}
export function botswana(): Override {
    return createCountryOverride("BW")()
}
export function brazil(): Override {
    return createCountryOverride("BR")()
}
export function brunei(): Override {
    return createCountryOverride("BN")()
}
export function bulgaria(): Override {
    return createCountryOverride("BG")()
}
export function burkinafaso(): Override {
    return createCountryOverride("BF")()
}
export function burundi(): Override {
    return createCountryOverride("BI")()
}

export function cambodia(): Override {
    return createCountryOverride("KH")()
}
export function cameroon(): Override {
    return createCountryOverride("CM")()
}
export function canada(): Override {
    return createCountryOverride("CA")()
}
export function capeverde(): Override {
    return createCountryOverride("CV")()
}
export function centralafricanrepublic(): Override {
    return createCountryOverride("CF")()
}
export function chad(): Override {
    return createCountryOverride("TD")()
}
export function chile(): Override {
    return createCountryOverride("CL")()
}
export function china(): Override {
    return createCountryOverride("CN")()
}
export function colombia(): Override {
    return createCountryOverride("CO")()
}
export function comoros(): Override {
    return createCountryOverride("KM")()
}
export function congo(): Override {
    return createCountryOverride("CG")()
}
export function costarica(): Override {
    return createCountryOverride("CR")()
}
export function croatia(): Override {
    return createCountryOverride("HR")()
}
export function cuba(): Override {
    return createCountryOverride("CU")()
}
export function cyprus(): Override {
    return createCountryOverride("CY")()
}
export function czechrepublic(): Override {
    return createCountryOverride("CZ")()
}

export function denmark(): Override {
    return createCountryOverride("DK")()
}
export function djibouti(): Override {
    return createCountryOverride("DJ")()
}
export function dominica(): Override {
    return createCountryOverride("DM")()
}
export function dominicanrepublic(): Override {
    return createCountryOverride("DO")()
}

export function easttimor(): Override {
    return createCountryOverride("TL")()
}
export function ecuador(): Override {
    return createCountryOverride("EC")()
}
export function egypt(): Override {
    return createCountryOverride("EG")()
}
export function elsalvador(): Override {
    return createCountryOverride("SV")()
}
export function equatorialguinea(): Override {
    return createCountryOverride("GQ")()
}
export function eritrea(): Override {
    return createCountryOverride("ER")()
}
export function estonia(): Override {
    return createCountryOverride("EE")()
}
export function eswatini(): Override {
    return createCountryOverride("SZ")()
}
export function ethiopia(): Override {
    return createCountryOverride("ET")()
}

export function fiji(): Override {
    return createCountryOverride("FJ")()
}
export function finland(): Override {
    return createCountryOverride("FI")()
}
export function france(): Override {
    return createCountryOverride("FR")()
}

export function gabon(): Override {
    return createCountryOverride("GA")()
}
export function gambia(): Override {
    return createCountryOverride("GM")()
}
export function georgia(): Override {
    return createCountryOverride("GE")()
}
export function germany(): Override {
    return createCountryOverride("DE")()
}
export function ghana(): Override {
    return createCountryOverride("GH")()
}
export function greece(): Override {
    return createCountryOverride("GR")()
}
export function grenada(): Override {
    return createCountryOverride("GD")()
}
export function guatemala(): Override {
    return createCountryOverride("GT")()
}
export function guinea(): Override {
    return createCountryOverride("GN")()
}
export function guineabissau(): Override {
    return createCountryOverride("GW")()
}
export function guyana(): Override {
    return createCountryOverride("GY")()
}

export function haiti(): Override {
    return createCountryOverride("HT")()
}
export function honduras(): Override {
    return createCountryOverride("HN")()
}
export function hungary(): Override {
    return createCountryOverride("HU")()
}

export function iceland(): Override {
    return createCountryOverride("IS")()
}
export function india(): Override {
    return createCountryOverride("IN")()
}
export function indonesia(): Override {
    return createCountryOverride("ID")()
}
export function iran(): Override {
    return createCountryOverride("IR")()
}
export function iraq(): Override {
    return createCountryOverride("IQ")()
}
export function ireland(): Override {
    return createCountryOverride("IE")()
}
export function israel(): Override {
    return createCountryOverride("IL")()
}
export function italy(): Override {
    return createCountryOverride("IT")()
}
export function ivorycoast(): Override {
    return createCountryOverride("CI")()
}

export function jamaica(): Override {
    return createCountryOverride("JM")()
}
export function japan(): Override {
    return createCountryOverride("JP")()
}
export function jordan(): Override {
    return createCountryOverride("JO")()
}

export function kazakhstan(): Override {
    return createCountryOverride("KZ")()
}
export function kenya(): Override {
    return createCountryOverride("KE")()
}
export function kiribati(): Override {
    return createCountryOverride("KI")()
}
export function kuwait(): Override {
    return createCountryOverride("KW")()
}
export function kyrgyzstan(): Override {
    return createCountryOverride("KG")()
}

export function laos(): Override {
    return createCountryOverride("LA")()
}
export function latvia(): Override {
    return createCountryOverride("LV")()
}
export function lebanon(): Override {
    return createCountryOverride("LB")()
}
export function lesotho(): Override {
    return createCountryOverride("LS")()
}
export function liberia(): Override {
    return createCountryOverride("LR")()
}
export function libya(): Override {
    return createCountryOverride("LY")()
}
export function liechtenstein(): Override {
    return createCountryOverride("LI")()
}
export function lithuania(): Override {
    return createCountryOverride("LT")()
}
export function luxembourg(): Override {
    return createCountryOverride("LU")()
}

export function madagascar(): Override {
    return createCountryOverride("MG")()
}
export function malawi(): Override {
    return createCountryOverride("MW")()
}
export function malaysia(): Override {
    return createCountryOverride("MY")()
}
export function maldives(): Override {
    return createCountryOverride("MV")()
}
export function mali(): Override {
    return createCountryOverride("ML")()
}
export function malta(): Override {
    return createCountryOverride("MT")()
}
export function marshallislands(): Override {
    return createCountryOverride("MH")()
}
export function mauritania(): Override {
    return createCountryOverride("MR")()
}
export function mauritius(): Override {
    return createCountryOverride("MU")()
}
export function mexico(): Override {
    return createCountryOverride("MX")()
}
export function micronesia(): Override {
    return createCountryOverride("FM")()
}
export function moldova(): Override {
    return createCountryOverride("MD")()
}
export function monaco(): Override {
    return createCountryOverride("MC")()
}
export function mongolia(): Override {
    return createCountryOverride("MN")()
}
export function montenegro(): Override {
    return createCountryOverride("ME")()
}
export function morocco(): Override {
    return createCountryOverride("MA")()
}
export function mozambique(): Override {
    return createCountryOverride("MZ")()
}
export function myanmar(): Override {
    return createCountryOverride("MM")()
}

export function namibia(): Override {
    return createCountryOverride("NA")()
}
export function nauru(): Override {
    return createCountryOverride("NR")()
}
export function nepal(): Override {
    return createCountryOverride("NP")()
}
export function netherlands(): Override {
    return createCountryOverride("NL")()
}
export function newzealand(): Override {
    return createCountryOverride("NZ")()
}
export function nicaragua(): Override {
    return createCountryOverride("NI")()
}
export function niger(): Override {
    return createCountryOverride("NE")()
}
export function nigeria(): Override {
    return createCountryOverride("NG")()
}
export function northkorea(): Override {
    return createCountryOverride("KP")()
}
export function northmacedonia(): Override {
    return createCountryOverride("MK")()
}
export function norway(): Override {
    return createCountryOverride("NO")()
}

export function oman(): Override {
    return createCountryOverride("OM")()
}

export function pakistan(): Override {
    return createCountryOverride("PK")()
}
export function palau(): Override {
    return createCountryOverride("PW")()
}
export function palestine(): Override {
    return createCountryOverride("PS")()
}
export function panama(): Override {
    return createCountryOverride("PA")()
}
export function papuanewguinea(): Override {
    return createCountryOverride("PG")()
}
export function paraguay(): Override {
    return createCountryOverride("PY")()
}
export function peru(): Override {
    return createCountryOverride("PE")()
}
export function philippines(): Override {
    return createCountryOverride("PH")()
}
export function poland(): Override {
    return createCountryOverride("PL")()
}
export function portugal(): Override {
    return createCountryOverride("PT")()
}

export function qatar(): Override {
    return createCountryOverride("QA")()
}

export function romania(): Override {
    return createCountryOverride("RO")()
}
export function russia(): Override {
    return createCountryOverride("RU")()
}
export function rwanda(): Override {
    return createCountryOverride("RW")()
}

export function saintkittsandnevis(): Override {
    return createCountryOverride("KN")()
}
export function saintlucia(): Override {
    return createCountryOverride("LC")()
}
export function saintvincentandthegrenadines(): Override {
    return createCountryOverride("VC")()
}
export function samoa(): Override {
    return createCountryOverride("WS")()
}
export function sanmarino(): Override {
    return createCountryOverride("SM")()
}
export function saotomeandprincipe(): Override {
    return createCountryOverride("ST")()
}
export function saudiarabia(): Override {
    return createCountryOverride("SA")()
}
export function senegal(): Override {
    return createCountryOverride("SN")()
}
export function serbia(): Override {
    return createCountryOverride("RS")()
}
export function seychelles(): Override {
    return createCountryOverride("SC")()
}
export function sierraleone(): Override {
    return createCountryOverride("SL")()
}
export function singapore(): Override {
    return createCountryOverride("SG")()
}
export function slovakia(): Override {
    return createCountryOverride("SK")()
}
export function slovenia(): Override {
    return createCountryOverride("SI")()
}
export function solomonislands(): Override {
    return createCountryOverride("SB")()
}
export function somalia(): Override {
    return createCountryOverride("SO")()
}
export function southafrica(): Override {
    return createCountryOverride("ZA")()
}
export function southkorea(): Override {
    return createCountryOverride("KR")()
}
export function southsudan(): Override {
    return createCountryOverride("SS")()
}
export function spain(): Override {
    return createCountryOverride("ES")()
}
export function srilanka(): Override {
    return createCountryOverride("LK")()
}
export function sudan(): Override {
    return createCountryOverride("SD")()
}
export function suriname(): Override {
    return createCountryOverride("SR")()
}
export function sweden(): Override {
    return createCountryOverride("SE")()
}
export function switzerland(): Override {
    return createCountryOverride("CH")()
}
export function syria(): Override {
    return createCountryOverride("SY")()
}

export function taiwan(): Override {
    return createCountryOverride("TW")()
}
export function tajikistan(): Override {
    return createCountryOverride("TJ")()
}
export function tanzania(): Override {
    return createCountryOverride("TZ")()
}
export function thailand(): Override {
    return createCountryOverride("TH")()
}
export function togo(): Override {
    return createCountryOverride("TG")()
}
export function tonga(): Override {
    return createCountryOverride("TO")()
}
export function trinidadandtobago(): Override {
    return createCountryOverride("TT")()
}
export function tunisia(): Override {
    return createCountryOverride("TN")()
}
export function turkey(): Override {
    return createCountryOverride("TR")()
}
export function turkmenistan(): Override {
    return createCountryOverride("TM")()
}
export function tuvalu(): Override {
    return createCountryOverride("TV")()
}

export function uganda(): Override {
    return createCountryOverride("UG")()
}
export function ukraine(): Override {
    return createCountryOverride("UA")()
}
export function unitedarabemirates(): Override {
    return createCountryOverride("AE")()
}
export function uk(): Override {
    return createCountryOverride("GB")()
}
export function usa(): Override {
    return createCountryOverride("US")()
}
export function uruguay(): Override {
    return createCountryOverride("UY")()
}
export function uzbekistan(): Override {
    return createCountryOverride("UZ")()
}

export function vanuatu(): Override {
    return createCountryOverride("VU")()
}
export function vatican(): Override {
    return createCountryOverride("VA")()
}
export function venezuela(): Override {
    return createCountryOverride("VE")()
}
export function vietnam(): Override {
    return createCountryOverride("VN")()
}

export function yemen(): Override {
    return createCountryOverride("YE")()
}

export function zambia(): Override {
    return createCountryOverride("ZM")()
}
export function zimbabwe(): Override {
    return createCountryOverride("ZW")()
}
